import React from "react";
import { Link } from "react-router-dom";
import "./main-button.scss";

function MainButton(props) {
  if (props.action === "submit") {
    return (
      <button
        className={`MainButton ${props.type} ${props.size}`}
        type="submit"
      >
        {props.title}
      </button>
    );
  } else {
    return (
      <Link
        to={`/${props.link}`}
        className={`MainButton ${props.type} ${props.size}`}
      >
        {props.title}
      </Link>
    );
  }
}

export { MainButton };

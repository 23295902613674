import React from "react";
import { usePardela } from "../../contexts/PardelaContext";
import { Link } from "react-router-dom";
import { Banner } from "../../components/Banner/Banner";
import { ImageInfo } from "../../components/ImageInfo/ImageInfo";
import { Hero } from "../../components/Hero/Hero";
import { Experience } from "../../components/Experience/Experience";
import { TextImgCarousel } from "../../components/TextImgCarousel/TextImgCarousel";
import { ContactHero } from "../../components/ContactHero/ContactHero";
import { TestimonialsCarousel } from "../../components/TestimonialsCarousel/TestimonialsCarousel";
import "./home.scss";

function Home() {
  const { views, setCurrentView } = usePardela();

  const bannerDescription = `This solution for all petroleum industry businesses on 
  earth provides a variety of energy and oil power that makes your business smoother and more stable`;

  const carouselItems = [
    {
      id: 1,
      title: "Transporte Portacontenedores",
      details: `Contamos con un equipo que conoce a fondo las diferentes leyes y normas que se aplican al transporte nacional. Solventaremos cualquier problema que pueda ocurrir en el trayecto de la mercancía desde el punto de origen hasta el punto de destino.`,
      imgUrl:
        "https://i.ibb.co/sVDyFRd/prdl-transporte-portacontenedores-1.jpg",
      imgAlt: "Wheel",
    },
    {
      id: 2,
      title: "Transporte Portacontenedores",
      details: `Contamos con un equipo que conoce a fondo las diferentes leyes y normas que se aplican al transporte nacional. Solventaremos cualquier problema que pueda ocurrir en el trayecto de la mercancía desde el punto de origen hasta el punto de destino.`,
      imgUrl:
        "https://i.ibb.co/wM7Fym2/prdl-carousel-1.jpg",
      imgAlt: "Transporte Portacontenedores",
    },
    {
      id: 3,
      title: "Rastreo Satelital",
      details: `Operatividad (hábitos de manejo, rendimiento, prevención de accidentes). 
      Seguridad (alertas, protocolos de reacción, mitigación de riesgos contra robos). 
      Logística (geoposición, productividad, servicio a cliente). 
      Mantenimiento (prevención, bitácora, detección de fallas).`,
      imgUrl: "https://i.ibb.co/59PNHds/prdl-gps.jpg",
      imgAlt: "GPS",
    },
  ];

  return (
    <div className="Home">
      <Banner
        title="Soluciones Integrales de Transporte"
        description={bannerDescription}
      >
        <a
          href="https://wa.link/zk60ru"
          className="MainButton primary sm"
          target="_blank"
          rel="noreferrer"
        >
          Hablar con asesor
        </a>
      </Banner>
      <ImageInfo
        invert={true}
        meta="Pardela"
        description={`En Pardela realizamos entregas a nivel nacional, 
        con la mayor seguridad y profesionalidad para nuestros clientes, 
        con la certeza de que las mercancías llegarán a su destino con la mayor 
        agilidad y en óptimas condiciones.`}
        imgUrl="https://i.ibb.co/tPsqT7W/prdl-asset-1.jpg"
        imgDescription="Pardela truck"
      >
        <Link
          to={views.nosotros}
          className="Home__btn--transparent"
          onClick={() => {
            setCurrentView(views.nosotros);
          }}
        >
          Conocer más &rarr;
        </Link>
      </ImageInfo>
      <Experience />
      <TextImgCarousel
        title="Conocimiento para cualquier exigencia"
        meta="Capabilities"
        carouselItems={carouselItems}
      />
      <Hero />
      <TestimonialsCarousel />
      <ContactHero />
    </div>
  );
}

export { Home };

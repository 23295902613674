import React from "react";
import "./privacy.scss"

function Privacy() {
  return (
    <main className="Privacy">
      <div className="Privacy__nav-background"></div>
      <section className="Privacy__info">
        <h2 className="Privacy__title">Políticas de Privacidad</h2>
        <h4 className="Privacy__subtitle">Protección de datos personales</h4>
        <p className="Privacy__details">
          Los Usuarios y Socios registrados de <strong>PARDELA LOGISTICS</strong> en el sitio de internet
          https://pardelalogistics.com.mx (en lo sucesivo “EL SITIO”) se obligan y
          comprometen a usar y a navegar por EL SITIO antes señalado y a
          utilizar el contenido del mismo de buena fe y bajo su propia
          responsabilidad.
        </p>
        <p className="Privacy__details">
          Del mismo modo, hacemos de su conocimiento que el llenado de cualquier
          formulario existente en EL SITIO o la remisión de un correo
          electrónico a cualquiera de nuestros buzones implica la aceptación de
          esta política de privacidad, así como la autorización a <strong>PARDELA LOGISTICS</strong> para
          que use y trate la información y datos personales que usted nos
          facilite para los fines a que haya lugar en relación con EL SITIO.
        </p>
        <p className="Privacy__details">
          Por la mera visita a EL SITIO los Usuarios no facilitan información
          personal alguna ni quedan obligados a facilitarla. Solicitamos su
          información personal ya que nos ayuda a brindarle un mejor servicio y
          con ella podemos ofrecerle acceso a nuestra gama de productos y
          servicios. Enunciativa, más no limitativamente, dentro de la
          información que le solicitaremos, están su(s) nombre(s) y apellidos
          completos, dirección de correo electrónico, teléfonos y nombre de su
          empresa.
        </p>
        <p className="Privacy__details">
          El servidor en donde está alojado EL SITIO utiliza cookies para
          mejorar el servicio prestado por <strong>PARDELA LOGISTICS</strong>. Estas cookies se instalan
          automáticamente en la computadora empleada por los Socios y Usuarios
          de <strong>PARDELA LOGISTICS</strong> pero no almacenan ningún tipo de información relativa a
          éstos.
        </p>
        <p className="Privacy__details">
          <strong>PARDELA LOGISTICS</strong> se compromete a guardar la máxima protección y
          confidencialidad sobre la información que usted nos facilite por medio
          de EL SITIO y a utilizarla únicamente para los fines indicados.
        </p>
        <p className="Privacy__details">
          Cuando se encuentre en EL SITIO y le pidan datos personales, usted
          compartirá la información solo con <strong>PARDELA LOGISTICS</strong> salvo que se especifique
          lo contrario.
        </p>
        <p className="Privacy__details">
          <strong>PARDELA LOGISTICS</strong> no compartirá la información confidencial con terceras
          partes, excepto que tenga expresa autorización de quienes se
          suscribieron, o cuando haya sido requerido por orden judicial para
          cumplir con las disposiciones procesales.
        </p>
        <p className="Privacy__details">
          <strong>PARDELA LOGISTICS</strong> no vende ni alquila la información de los usuarios. Si los
          datos personales del usuario debieran ser compartidos con socios
          comerciales o patrocinantes, el usuario será notificado antes que
          éstos sean recogidos o transferidos. Si el usuario no desea que sus
          datos sean compartidos, puede decidir no utilizar un servicio
          determinado o no participar en algunas promociones o concursos.
        </p>
        <p className="Privacy__details">
          <strong>PARDELA LOGISTICS</strong> puede difundir las estadísticas en conjunto de los usuarios
          (por ejemplo, el porcentaje de nuestros usuarios que son mujeres o
          mayores a determinada edad, etc.) para describir nuestros servicios y
          para otros propósitos lícitos en los casos que marque la ley.
        </p>
        <p className="Privacy__details">
          <strong>PARDELA LOGISTICS</strong> puede difundir la información de la cuenta en casos
          especiales cuando pensemos que proporcionar esta información puede
          servir para identificar, localizar o realizar acciones legales contra
          personas que pudiesen infringir las condiciones del servicio de los
          sitios de <strong>PARDELA LOGISTICS</strong> o causar daños o interferencia sobre los derechos
          de <strong>PARDELA LOGISTICS</strong> o sus propiedades, de otros usuarios de EL SITIO de
          <strong>PARDELA LOGISTICS</strong> o de cualquier otra persona que pudiese resultar perjudicada
          por dichas actividades. <strong>PARDELA LOGISTICS</strong> puede difundir u obtener acceso a la
          información de cuenta cuando, actuando de buena fe, creamos que es
          necesario por razones legales, administrativas o de otra índole y lo
          consideremos necesario para mantener, proporcionar y desarrollar
          nuestros productos y servicios.
        </p>
        <p className="Privacy__details">
          <strong>PARDELA LOGISTICS</strong> no asume ninguna obligación de mantener confidencial
          cualquier otra información que el usuario proporcione, incluyendo
          aquella información que el usuario proporcione a través de boletines y
          pláticas en línea (chats), así como la información que obtenga a
          través de los cookies que se describieron anteriormente, lo anterior
          en términos de lo establecido en el artículo 109 de la Ley Federal de
          los Derechos de Autor y de la fracción I, del artículo 76 bis de la
          Ley Federal de Protección al Consumidor.
        </p>
        <p className="Privacy__details">
          <strong>PARDELA LOGISTICS</strong> presume que los datos han sido introducidos por su titular o
          por una persona autorizada por éste, siendo en ambos casos mayores de
          edad, así como que son verdaderos, correctos y exactos.
        </p>
        <p className="Privacy__details">
          <strong>PARDELA LOGISTICS</strong> ha adoptado los niveles de seguridad adecuados a los datos
          facilitados por sus Usuarios y/o Socios y, además, ha instalado todos
          los medios y medidas a su alcance para evitar, en la medida de lo
          posible, la pérdida, mal uso, alteración, acceso no autorizado y
          extracción de los mismos, sin embargo, al aceptar las presentes
          Políticas de Privacidad, el Usuario y/o Socio expresamente acepta y
          reconoce que <strong>PARDELA LOGISTICS</strong> no será responsable por el acceso y uso no
          autorizado de dicha información, o en violación a los sistemas de
          seguridad que <strong>PARDELA LOGISTICS</strong> ha instalado por parte de terceros.
        </p>
        <p className="Privacy__details">
          A continuación, <strong>PARDELA LOGISTICS</strong> le expondrá brevemente los principios que
          rigen sus políticas de privacidad y confidencialidad para la
          información proporcionada por los usuarios de sus servicios
          electrónicos en línea:
        </p>
        <ol className="Privacy__list">
          <li className="Privacy__list-element">
            <strong>PARDELA LOGISTICS</strong> únicamente recabará la información del socio que es
            necesaria para brindarle los servicios personalizados que se
            encuentran disponibles en sus sitios en Internet, la cual se
            utilizará únicamente para los fines que es solicitada.
          </li>
          <li className="Privacy__list-element">
            <strong>PARDELA LOGISTICS</strong> úse esfuerza por asegurar la calidad de la información que
            se recaba sobre nuestros usuarios de servicios en línea,
            particularmente cuando se haya obtenido a través de algún proveedor
            de información o de servicios.
          </li>
          <li className="Privacy__list-element">
            <strong>PARDELA LOGISTICS</strong> enfoca sus esfuerzos para ofrecer la tecnología más
            moderna y actualizada a fin de ofrecer a sus socios/usuarios la
            mayor seguridad posible en el manejo y transferencia de la
            información que es requerida en los diversos procesos de
            requerimiento de datos.
          </li>
          <li className="Privacy__list-element">
            <strong>PARDELA LOGISTICS</strong> evita la divulgación de información acerca del
            socio/usuario, haciéndolo únicamente respecto de aquellos datos que
            expresamente sean autorizados para ello.
          </li>
          <li className="Privacy__list-element">
            En todo momento, <strong>PARDELA LOGISTICS</strong> está atento a las inquietudes que
            manifiesten nuestros socios/usuarios respecto al manejo de la
            información que proporcionen para los diversos procesos en línea.
          </li>
          <li className="Privacy__list-element">
            <strong>PARDELA LOGISTICS</strong> cuida de que estos principios de
            privacidad/confidencialidad se extiendan al conjunto de relaciones
            comerciales al interior del grupo.
          </li>
          <li className="Privacy__list-element">
            <strong>PARDELA LOGISTICS</strong> comparte la responsabilidad del cuidado de la información
            con sus empleados, haciéndolos copartícipes de los lineamientos
            expuestos en los documentos de "Políticas de privacidad".
          </li>
        </ol>
        <p className="Privacy__details">
          Sin embargo, y a pesar de contar cada día con herramientas más
          seguras, la protección de los datos enviados a través de Internet no
          se puede garantizar al 100%; por lo que una vez recibidos, se hará
          todo lo posible por salvaguardar la información.
        </p>
      </section>
    </main>
  );
}
export { Privacy };

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Nav } from "./components/Nav/Nav";
import { Footer } from "./components/Footer/Footer";
import { Home } from "./views/Home/Home";
import { AboutUs } from "./views/AboutUs/AboutUs";
import { Services } from "./views/Services/Services";
import { Contact } from "./views/Contact/Contact";
import { Technology } from "./views/Technology/Technology";
import { Privacy } from "./views/Privacy/Privacy";

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nosotros" element={<AboutUs />} />
          <Route path="/servicios" element={<Services />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/tecnologia" element={<Technology />} />
          <Route path="/privacidad" element={<Privacy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export { App };

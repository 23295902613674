import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./jobs.scss";

function Jobs() {
  return (
    <section className="Jobs" id="jobs">
      <div className="section-header">
        <p className="meta-description">Trabaja con nosotros</p>
        <h3 className="section-header__title">
          Únete a nuestro equipo de trabajo
        </h3>
        <p className="section-header__details">
          Our philosophy is simple — hire a team of diverse, passionate people
          and foster a culture that empowers you to do you best work.
        </p>
      </div>
      <div className="Jobs__vacancies">
        <Accordion defaultActiveKey="0" className="Jobs__accordion">
          <Accordion.Item eventKey="0" className="Jobs__accordion-item">
            <Accordion.Header>Operador full</Accordion.Header>
            <Accordion.Body>
              <strong>Edad: </strong>23-60 <br />
              <strong>Sexo: </strong>indistinto Experiencia mínima de 2-3 años
              <br />
              <strong>Requisitos Generales</strong> <br />
              <ul>
                <li>Licencia Federal tipo B, o B-E (doble articulado)</li>
                <li>Examen medico APTO</li>
                <li>Comprobar los últimos 3 trabajos laborales</li>
              </ul>
              <strong>Ventajas y Beneficios</strong>
              <ul>
                <li>Capacitación y contratación</li>
                <li>Equipo de proteccion personal</li>
                <li>Pago por kilometraje</li>
                <li>Bono de productividad</li>
                <li>Prestaciones de Ley</li>
              </ul>
              <div className="Jobs__accordion-contact-banner">
                Interesados mandar CV y documentos a{" "}
                <a href="mailto:recursoshumanos@pardelalogistics.com">
                  recursoshumanos@pardelalogistics.com
                </a>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="Jobs__accordion-item">
            <Accordion.Header>Monitorista</Accordion.Header>
            <Accordion.Body>
              <strong>Edad: </strong>27-60 <br />
              <strong>Sexo: </strong>indistinto <br />
              <strong>Experiencia: </strong>mínima de 2-3 años <br />
              <strong>Requisitos Generales</strong> <br />
              <ul>
                <li>
                  Experiencia en el sector transporte (especialmente foráneo)
                </li>
                <li>Manejo de plataformas GPS</li>
                <li>Conocimiento protocolos de Seguridad</li>
                <li>
                  Conocimiento de rutas y normas de circulación en carreteras
                  federales.
                </li>
              </ul>
              <strong>Ventajas y Beneficios</strong>
              <ul>
                <li>Capacitación y contratación</li>
                <li>Equipo de proteccion personal</li>
                <li>Pago por kilometraje</li>
                <li>Bono de productividad</li>
                <li>Prestaciones de Ley</li>
              </ul>
              <div className="Jobs__accordion-contact-banner">
                Interesados mandar CV y documentos a{" "}
                <a href="mailto:recursoshumanos@pardelalogistics.com">
                  recursoshumanos@pardelalogistics.com
                </a>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="Jobs__accordion-item">
            <Accordion.Header>Coordinador de operaciones Zona de manzanillo y San Jóse</Accordion.Header>
            <Accordion.Body>
              <strong>Edad: </strong>23-60 <br />
              <strong>Sexo: </strong>indistinto <br />
              <strong>Experiencia: </strong>mínima de 2-3 años <br />
              <strong>Requisitos Generales</strong> <br />
              <ul>
                <li>
                  Experiencia en gestión de flota
                </li>
                <li>Revisión de unidades</li>
                <li>Bitácoras de viaje</li>
                <li>
                  Uso de escáner para reporte de unidades después de cada viaje
                </li>
                <li>
                  Inspección de unidades por viaje, bitácoras de herramientas en taller
                </li>
              </ul>
              <strong>Ventajas y Beneficios</strong>
              <ul>
                <li>Bono de productividad</li>
                <li>Prestaciones de Ley</li>
              </ul>
              <div className="Jobs__accordion-contact-banner">
                Interesados mandar CV y documentos a{" "}
                <a href="mailto:recursoshumanos@pardelalogistics.com">
                  recursoshumanos@pardelalogistics.com
                </a>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </section>
  );
}

export { Jobs };

import { useState } from "react";
import { Link } from "react-router-dom";
import { usePardela } from "../../contexts/PardelaContext";
import "./nav.scss";

function Nav() {
  const [showBackground, setShowBackground] = useState(false);

  const {
    views,
    showMobileNav,
    currentView,
    setShowMobileNav,
    setCurrentView,
  } = usePardela();

  window.onscroll = () => {
    if (window.scrollY > 150) {
      setShowBackground(true);
    } else {
      setShowBackground(false);
    }
  };

  return (
    <header
      className={`Nav-wrapper ${showBackground && "scroll"}`}
      onScroll={() => {
        if (window.scrollY > 150) setShowBackground(true);
      }}
    >
      <nav className="Nav">
        <div className="Nav__logo">
          <Link to="/" onClick={() => setCurrentView(views.home)}>
            <img src="./img/prdl_logo.png" alt="Pardela Logo" />
          </Link>
        </div>
        <button
          className="Nav__hamburger-btn Icon"
          onClick={() => setShowMobileNav((prev) => !prev)}
          aria-label="Menu button"
        ></button>
        <ul className={`Nav__links ${showMobileNav && "show"}`}>
          <button
            className={`Nav__close-btn Icon ${showMobileNav && "show"}`}
            onClick={() => setShowMobileNav((prev) => !prev)}
          ></button>
          <li className="Nav__item">
            <Link
              to={views.home}
              className={`Nav__link ${currentView === views.home && "active"}`}
              onClick={() => {
                setCurrentView(views.home);
                setShowMobileNav(false);
              }}
            >
              Home
            </Link>
          </li>
          <li className="Nav__item">
            <Link
              to={views.nosotros}
              className={`Nav__link ${
                currentView === views.nosotros && "active"
              }`}
              onClick={() => {
                setCurrentView(views.nosotros);
                setShowMobileNav(false);
              }}
            >
              Nosotros
            </Link>
          </li>
          <li className="Nav__item">
            <Link
              to={views.servicios}
              className={`Nav__link ${
                currentView === views.servicios && "active"
              }`}
              onClick={() => {
                setCurrentView(views.servicios);
                setShowMobileNav(false);
              }}
            >
              Servicios
            </Link>
          </li>
          <li className="Nav__item">
            <Link
              to={views.tecnologia}
              className={`Nav__link ${
                currentView === views.tecnologia && "active"
              }`}
              onClick={() => {
                setCurrentView(views.tecnologia);
                setShowMobileNav(false);
              }}
            >
              Tecnología
            </Link>
          </li>
          <li className="Nav__item">
            <Link
              to={views.contacto}
              className={`Nav__link ${
                currentView === views.contacto && "active"
              }`}
              onClick={() => {
                setCurrentView(views.contacto);
                setShowMobileNav(false);
              }}
            >
              Contacto
            </Link>
          </li>
          <li className="Nav__item">
            <a
              href={views.operadores}
              className={`Nav__link ${
                currentView === views.operadores && "active"
              }`}
              onClick={() => {
                setCurrentView(views.operadores);
                setShowMobileNav(false);
              }}
            >
              Operadores
            </a>
          </li>
          <li className="Nav__item">
            <a
              href="https://wa.link/zk60ru"
              className="MainButton primary sm"
              target="_blank"
              rel="noreferrer"
            >
              Hablar con asesor
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export { Nav };

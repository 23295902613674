import { useState } from "react";
import * as emailjs from "emailjs-com";
import { Banner } from "../../components/Banner/Banner";
import { MainButton } from "../../components/MainButton/MainButton";
import { ContactInfoBlock } from "./ContactInfoBlock";
import { Jobs } from "../../components/Jobs/Jobs";
import "./contact.scss";

function Contact() {
  const bannerDescription = `No dudes en ponerte en contacto con nosotros`;

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const buttonTitle = loading ? "Enviando..." : "Enviar";

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const service_id = process.env.REACT_APP_SERVICE_ID;
    const template_id = process.env.REACT_APP_TEMPLATE_ID;
    const public_key = process.env.REACT_APP_PUBLIC_KEY;
    const template_params = {
      from_name: email,
      message: message,
    };

    emailjs
      .send(service_id, template_id, template_params, public_key)
      .then((response) => {
        console.log(response.status, response.text);
        if (response.status === 200) {
          setEmail("");
          setMessage("");
          setError(false);
          setSuccess(true);
        } else {
          setError(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <div className="Contact">
      <Banner title="Contacto" description={bannerDescription}></Banner>
      <div className="Contact__content">
        <main className="Contact__details">
          <div className="Contact__contact-info">
            <p className="Contact__meta">Contacto</p>
            <h3 className="Contact__header">Estamos cerca de ti</h3>
            <p className="Contact__description">
              ¿Dudas y preguntas? Llámenos o envíenos un correo electrónico y
              con gusto nos pondremos en contacto con usted.
            </p>
            <ContactInfoBlock
              iconUrl="https://i.ibb.co/mFmXfyg/prdl-icon-envelope.png"
              iconDescription="Envelope"
              title="Email"
            >
              <a
                href="mailto:contacto@pardelalogistics.com"
                target="_blank"
                rel="noreferrer"
              >
                contacto@pardelalogistics.com
              </a>
            </ContactInfoBlock>
            <ContactInfoBlock
              iconUrl="https://i.ibb.co/gSmmycg/prdl-icon-phone.png"
              iconDescription="Phone"
              title="Teléfono de contacto"
            >
              <a href="tel:5513763223">55 1376 3223</a>
            </ContactInfoBlock>
            <ContactInfoBlock
              iconUrl="https://i.ibb.co/2vfRrKq/prdl-icon-pin.png"
              iconDescription="Pin"
              title="Ubicación"
            >
              <p>Seneca 425 Colonia Polanco, Alcaldía Miguel Hidalgo</p>
              <p>C.P. 11560</p>
              <p>Ciudad de México</p>
            </ContactInfoBlock>
          </div>
          <form
            action=""
            className="Contact__form"
            onSubmit={(event) => handleSubmit(event)}
          >
            <div className="Contact__input-container">
              <label htmlFor="email" className="Contact__label">
                Email
              </label>
              <input
                className="Contact__input"
                type="email"
                placeholder="contacto@mail.com"
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="Contact__input-container">
              <label htmlFor="email" className="Contact__label">
                Mensaje
              </label>
              <textarea
                className="Contact__input"
                name="message"
                cols="30"
                rows="10"
                required
                placeholder="Tu mensaje..."
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              ></textarea>
            </div>
            {success && (
              <p className="Contact__form__success">
                Mensaje enviado con éxito.
              </p>
            )}
            {error && <p className="Contact__form__error">Ocurrió un error.</p>}
            <div className="Contact__input-container">
              <MainButton
                type="primary"
                size="md"
                title={buttonTitle}
                action="submit"
              />
            </div>
          </form>
        </main>
        <Jobs />
      </div>
    </div>
  );
}

export { Contact };

import React from "react";
import "./contact-hero.scss";

function ContactHero() {
  return (
    <div className="ContactHero">
      <img
        className="ContactHero__figure"
        src="./img/prdl_figure_dots.jpg"
        alt="Orange Dots"
      />
      <p className="ContactHero__meta meta-description">Contacto</p>
      <h3 className="ContactHero__header">Habla con nuestros expertos</h3>
      <div className="ContactHero__btn-container">
        <a
          href="https://wa.link/zk60ru/"
          target="_blank"
          rel="noreferrer"
          className="MainButton primary sm"
        >
          Llamar asesora
        </a>

        <a
          href="mailto:contacto@pardelalogistics.com"
          className="MainButton secondary sm"
          target="_blank"
          rel="noreferrer"
        >
          Contáctanos
        </a>
      </div>
    </div>
  );
}

export { ContactHero };

import React from "react";
import { usePardela } from "../../contexts/PardelaContext";
import { Banner } from "../../components/Banner/Banner";
import { MainButton } from "../../components/MainButton/MainButton";
import { ImageInfo } from "../../components/ImageInfo/ImageInfo";
import { TextImgCarousel } from "../../components/TextImgCarousel/TextImgCarousel";

import { OurTeam } from "../../components/OurTeam/OurTeam";
import { ContactHero } from "../../components/ContactHero/ContactHero";
import "./technology.scss";

function Technology() {
  const { views } = usePardela();

  const bannerDescription = `La tranquilidad de nuestros clientes es uno de los aspectos que más valoramos`;

  const carouselItems = [
    {
      id: 1,
      title: "Cámaras bi-direccionales",
      details: `Cámaras con inteligencia artificial para conocer a detalle el estado de nuestra unidad y la carga de nuestros clientes para prevenir cualquier incidente durante el traslado. De esta forma, podemos reaccionar de inmediato ante cualquier problema.`,
      imgUrl: "https://i.ibb.co/TgxDFfr/prdl-webfleet.jpg",
      imgAlt: "Webfleet",
    },
    {
      id: 2,
      title: "Rastreo de vehículos en tiempo real",
      details: `Nuestras unidades están en constante monitoreo 24/7 los 365 días del año. Teniendo nuestras unidades siempre localizadas nos permite anticiparnos ante cualquier problema y apoyar a nuestro operador con cualquier necesidad que pueda surgir.`,
      imgUrl: "https://i.ibb.co/hDJ34Tf/prdl-map.jpg",
      imgAlt: "GPS map",
    },
    {
      id: 3,
      title: "Siempre en comunicación",
      details: `Contamos con distintas herramientas y tecnologías que nos permiten siempre estar en contacto con nuestros operadores, evitando estar incomunicados por falta de señal o incidente, manteniendo retroalimentación con nuestros operadores.
      `,
      imgUrl:
        "https://i.ibb.co/wM7Fym2/prdl-carousel-1.jpg",
      imgAlt: "Webfleet",
    },
  ];

  const infoBlocks = [
    {
      title: "Equipos recientes y en óptimas condiciones",
      details: [
        `Contamos con unidades en las mejores condiciones para evitar fallas mecánicas en los trayectos, buscando dar una mayor confianza al cliente, con la seguradad de que su mercancía llegará a su destino.`,
      ],
      iconUrl:
        "https://i.ibb.co/h2PMGyJ/prdl-icon-folders.png",
      iconDesc: "Folders",
    },
    {
      title: "Equipos con todos sus mantenimientos y papeles",
      details: [
        `En Pardela contamos con dos tipos de servicios, preventivos y correctivos, haciendo que las reparaciones de las unidades sean leves, lo que hace más eficieonte la operación, dando mayor vida a las unidades.`,
      ],
      iconUrl:
        "https://i.ibb.co/h2PMGyJ/prdl-icon-folders.png",
      iconDesc: "Folders",
    },
    {
      title: "Tecnología",
      details: [
        `Contamos con tecnología a la vanguardia en materia de seguridad, buscando siempre tener el mayor número de herramientas para que el cliente tenga segura su mercancía y la satisfacción de nuestro servicio. `,
      ],
      iconUrl:
        "https://i.ibb.co/xmn5Fmt/prdl-icon-highway.png",
      iconDesc: "Highway",
    },
  ];

  return (
    <div className="Technology">
      <Banner title="Tecnología" description={bannerDescription}>
        <MainButton
          title="Ir a Servicios"
          type="primary"
          size="sm"
          link={views.servicios}
        />
      </Banner>
      <ImageInfo
        invert={false}
        meta="Vanguardia"
        header="Inteligencia Artificial"
        description={`Nuestra flota está equipada con un sistema que utiliza 
        inteligencia artificial para identificar comportamientos de riesgo de forma automática, 
        como una conducción distraída o el uso del teléfono móvil al volante. 
        Cuando esto ocurre, la cámara notifica al conductor con una alerta visual y de audio, 
        ayudándole a evitar situaciones peligrosas.`}
        imgUrl="https://i.ibb.co/mB1q6WM/prdl-tech.jpg"
        imgDescription="Monitor"
      >
        <img
          src="https://i.ibb.co/k9BGzqz/prdl-webfleet-logo.png"
          alt="Webfleet solutions logo"
        />
      </ImageInfo>
      <TextImgCarousel
        title="Tecnología abordo de nuestros camiones"
        meta="Unidad Inteligente"
        carouselItems={carouselItems}
      />
      <OurTeam
        title="Nuestro Equipo"
        imgUrl="https://i.ibb.co/Wg2MR7H/prdl-our-team.jpg"
        imgAlt="Truck wheel"
        info={infoBlocks}
      />
      <ContactHero />
    </div>
  );
}

export { Technology };

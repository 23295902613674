import React from "react";
import { usePardela } from "../../contexts/PardelaContext";
import { Banner } from "../../components/Banner/Banner";
import { MainButton } from "../../components/MainButton/MainButton";
import { Experience } from "../../components/Experience/Experience";
import { ImageInfo } from "../../components/ImageInfo/ImageInfo";
import "./about-us.scss";
import { OurTeam } from "../../components/OurTeam/OurTeam";

function AboutUs() {
  const { views } = usePardela();

  const infoBlocks = [
    {
      title: "Misión",
      details: [
        `Somos una empresa innovadora que provee soluciones integrales y especializadas en transporte, con altos estándares de calidad, excelencia y servicio, ocupados en dar una solución total a las necesidades de nuestros clientes y asociados, garantizando su satisfacción con tecnología de punta que se adapta, actualiza y transforma de acuerdo a sus necesidades.`,
      ],
      iconUrl: "https://i.ibb.co/h2PMGyJ/prdl-icon-folders.png",
      iconDesc: "Folders",
    },
    {
      title: "Visión",
      details: [
        `Consolidarnos como la empresa Mexicana líder y referente en soluciones integrales de transporte, cubriendo estándares tanto nacionales como internacionales de calidad y servicio, innovando día a día y superando las expectativas de nuestros clientes, proveedores y asociados, garantizando así, productos y servicios de excelencia y calidad.`,
      ],
      iconUrl: "https://i.ibb.co/h2PMGyJ/prdl-icon-folders.png",
      iconDesc: "Folders",
    },
    {
      title: "Valores",
      details: [
        `Honestidad: Somos congruentes con nuestra Misión y Visión en nuestro actuar día a día.`,

        `Respeto e inclusión: Reconocemos, aceptamos, apreciamos y valoramos las cualidades, tiempo y trabajo de cada uno de nuestros clientes, asociados, empleados y proveedores así como como el propio sin distinción de creencias políticas, religiosas, genero o preferencias sexuales.`,

        `Profesionalismo: Nos comprometemos con estricto apego a la cortesía, responsabilidad, honestidad y trabajamos con énfasis en la excelencia.`,
      ],
      iconUrl: "https://i.ibb.co/xmn5Fmt/prdl-icon-highway.png",
      iconDesc: "Highway",
    },
  ];

  return (
    <div className="AboutUs">
      <Banner title="¿Quiénes Somos?">
        <MainButton
          title="Cotizar"
          type="primary"
          size="sm"
          link={views.contacto}
        />
      </Banner>
      <ImageInfo
        meta="Empresa"
        header="Sobre Pardela Logistics"
        description={`Pardela Logistics es una empresa Mexicana líder en el mercado de transporte de carga, 
        con gran experiencia en el medio. Actualmente estamos enfocados en el transporte de mercancías 
        en el sector de portacontendores por vía carretera en viajes spot y equipos dedicados.`}
        extra_description={`Somos una empresa comprometida con mejorar la calidad de los servicios de transporte dentro de México, con seguridad de vanguardia y un trato cercano a los clientes, proveyendo una buena calidad de vida para sus operadores de transporte, así como siempre buscar ser mas eficientes y seguros nuestros traslados.`}
        imgUrl="https://i.ibb.co/ypq7CKN/prdl-about-us.jpg"
        imgDescription="Black and white trucks"
      >
        <img
          src="https://i.ibb.co/Bfh7YNL/prdl-figure-logo-gray-cropped.png"
          alt="Pardela Logo Gray"
          className="AboutUs__gray-logo"
        />
      </ImageInfo>
      <OurTeam
        title="Misión, Visión, Valores"
        imgUrl="https://i.ibb.co/R9b0X3Q/prdl-mision.png"
        imgAlt="White truck door"
        info={infoBlocks}
      />
      <Experience />
    </div>
  );
}

export { AboutUs };

import React from "react";
import { Link } from "react-router-dom";
import { usePardela } from "../../contexts/PardelaContext";
import "./footer.scss";

function Footer() {
  const { views, setCurrentView } = usePardela();

  return (
    <div className="Footer__wrapper">
      <footer className="Footer">
        <section className="Footer__top">
          <div className="Footer__top-section">
            <img
              className="Footer__logo"
              src="./img/prdl_logo.png"
              alt="Pardela Logo"
            />
            <p className="Footer__description">
              Pardela ofrece servicios integrados de logística y transporte a
              nivel nacional.
            </p>
          </div>
          <div className="Footer__top-section">
            <h2 className="Footer__top-header">Servicios</h2>
            <a
              className="Footer__link"
              href={`${views.servicios}#esquema-flete`}
            >
              Esquema por flete
            </a>
            <a
              className="Footer__link"
              href={`${views.servicios}#esquema-dedicado`}
            >
              Esquema dedicado
            </a>
            <a
              className="Footer__link"
              href={`${views.servicios}#admin-flotillas`}
            >
              Admin. de flotas
            </a>
          </div>
          <div className="Footer__top-section">
            <h2 className="Footer__top-header">Nosotros</h2>
            <Link
              className="Footer__link"
              to={views.nosotros}
              onClick={() => {
                setCurrentView(views.nosotros);
              }}
            >
              Quiénes somos
            </Link>
            <Link
              className="Footer__link"
              to={views.nosotros}
              onClick={() => {
                setCurrentView(views.nosotros);
              }}
            >
              Valores
            </Link>
            <Link
              className="Footer__link"
              to={views.contacto}
              onClick={() => {
                setCurrentView(views.contacto);
              }}
            >
              Contacto
            </Link>
          </div>
          <div className="Footer__top-section">
            <h2 className="Footer__top-header">Tecnología</h2>
            <Link
              className="Footer__link"
              to={views.tecnologia}
              onClick={() => {
                setCurrentView(views.tecnologia);
              }}
            >
              Inteligencia Artificial
            </Link>
          </div>
          <div className="Footer__top-section">
            <h2 className="Footer__top-header">Info</h2>
            <Link
              className="Footer__link"
              to={views.privacidad}
              onClick={() => {
                setCurrentView(views.privacidad);
              }}
            >
              Políticas de privacidad
            </Link>
            <Link className="Footer__link" to="#">
              Términos y condiciones
            </Link>
            <a
              className="Footer__link"
              href={views.operadores}
              onClick={() => {
                setCurrentView(views.operadores);
              }}
            >
              Contrataciones
            </a>
          </div>
        </section>
        <section className="Footer__bottom">
          <p className="Footer__copyright">
            Pardela Logistics SA de CV ®Todos los derechos Reservados
          </p>
          <div className="Footer__social-media">
            <a
              href="https://twitter.com/TransportesPar3"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="Footer__social-icon"
                src="https://i.ibb.co/K9gtwN6/prdl-social-tw.png"
                alt="Twitter logo"
              />
            </a>
            <a
              href="https://www.instagram.com/pardela.logistics/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="Footer__social-icon"
                src="https://i.ibb.co/WnxGpf9/prdl-social-ig.png"
                alt="Instagram logo"
              />
            </a>
          </div>
        </section>
      </footer>
    </div>
  );
}

export { Footer };

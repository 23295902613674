import React from "react";

function TestimonialsCarouselItem({ name, position, details, imgUrl }) {
  return (
    <>
      <span className="TestimonialsCarouselItem__img">
        <img src={imgUrl} alt="Testimonial" />
      </span>
      <h4 className="TestimonialsCarouselItem__name">{name}</h4>
      <p className="TestimonialsCarouselItem__position">{position}</p>
      <p className="TestimonialsCarouselItem__details">{details}</p>
    </>
  );
}

export { TestimonialsCarouselItem };

import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { TestimonialsCarouselItem } from "./TestimonialsCarouselItem";
import "./testimonials-carousel.scss";

function TestimonialsCarousel() {
  const [index, setIndex] = useState(0);

  const testimonialsInfo = [
    {
      id: 1,
      name: "Mario Hernández",
      position: "Gerente comercial",
      details:
        "El servicio muy comunicativo, siempre tuvimos visibilidad de nuestra carga y estatus de parte de Pardela.",
      imgUrl: "https://i.ibb.co/rcrYmG5/prdl-testimony.png",
    },
    {
      id: 2,
      name: "Roberto Sierra",
      position: "Director",
      details:
        "El servicio salió en tiempo y forma, reportándonos en todo momento, el status de nuestra carga.",
      imgUrl: "https://i.ibb.co/rcrYmG5/prdl-testimony.png",
    },
    {
      id: 3,
      name: "Luis Fernando Covarrubias",
      position: "Logística",
      details:
        "Seguro y confiable, se nos comunicó las paradas autorizadas, dejándonos tranquilos y satisfechos en todo momento.",
      imgUrl: "https://i.ibb.co/rcrYmG5/prdl-testimony.png",
    },
  ];

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <section className="TestimonialsCarousel">
      <div className="section-header">
        <p className="meta-description">Testimoniales</p>
        <h3 className="section-header__title">
          Nuestros clientes lo reconocen
        </h3>
        <p className="section-header__details">Lo que dicen de nosotros</p>
      </div>
      <Carousel
        className="TestimonialsCarousel"
        activeIndex={index}
        onSelect={handleSelect}
      >
        {testimonialsInfo.map((testimonial, index) => (
          <Carousel.Item key={index}>
            <Carousel.Caption>
              <TestimonialsCarouselItem
                name={testimonial.name}
                position={testimonial.position}
                details={testimonial.details}
                imgUrl={testimonial.imgUrl}
              />
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
}

export { TestimonialsCarousel };

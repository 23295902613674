import React from "react";

function ContactInfoBlock({ iconUrl, iconDescription, title, children }) {
  return (
    <article className="ContactInfoBlock">
      <div className="ContactInfoBlock__icon-container">
        <img
          src={iconUrl}
          alt={iconDescription}
          className="ContactInfoBlock__icon"
        />
      </div>
      <h4 className="ContactInfoBlock__title">{title}</h4>
      {children}
    </article>
  );
}

export { ContactInfoBlock };

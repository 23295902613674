import React from "react";

function InfoBlock({ iconUrl, iconDescription, title, details, type }) {
  return (
    <article className={`InfoBlock ${type}`}>
      <div className="InfoBlock__icon-container">
        <img className="InfoBlock__icon" src={iconUrl} alt={iconDescription} />
      </div>
      <div className="InfoBlock__info-container">
        <h6 className="InfoBlock__title">{title}</h6>
        {details.map((detail, index) => (
          <p className="InfoBlock__details" key={index}>
            {detail}
          </p>
        ))}
      </div>
    </article>
  );
}

export { InfoBlock };

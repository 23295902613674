import React from "react";
import { usePardela } from "../../contexts/PardelaContext";
import { Link } from "react-router-dom";
import "./hero.scss";

function Hero() {
  const { views, setCurrentView, setShowMobileNav } = usePardela();

  return (
    <div className="Hero">
      <img
        className="Hero__figure"
        src="./img/prdl_figure_dots.jpg"
        alt="Orange Dots"
      />
      <div className="Hero__content">
        <h3 className="Hero__title">
          Contamos con una flotilla que te brinda certeza, seguridad y calidad
          de transporte
        </h3>

        <Link
          to={views.servicios}
          className="MainButton primary sm"
          onClick={() => {
            setCurrentView(views.servicios);
            setShowMobileNav(false);
          }}
        >
          Ir a servicios{" "}
        </Link>
      </div>
    </div>
  );
}

export { Hero };

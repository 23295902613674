import { useState, useContext, createContext, useEffect } from "react";

const PardelaContext = createContext();

function usePardela() {
  return useContext(PardelaContext);
}

function PardelaProvider({ children }) {
  const views = {
    home: "/",
    servicios: "servicios",
    nosotros: "nosotros",
    tecnologia: "tecnologia",
    contacto: "contacto",
    operadores: "contacto#jobs",
    privacidad: "privacidad"
  };

  const [showMobileNav, setShowMobileNav] = useState(false);
  const [currentView, setCurrentView] = useState(views.home);

  useEffect(() => {
    window.scroll(0, 0);
  }, [currentView]);

  return (
    <PardelaContext.Provider
      value={{
        views,
        showMobileNav,
        currentView,
        setShowMobileNav,
        setCurrentView,
      }}
    >
      {children}
    </PardelaContext.Provider>
  );
}

export { usePardela, PardelaProvider };

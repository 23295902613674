import React from "react";
import "./image-info.scss";

function ImageInfo({
  invert,
  meta,
  header,
  description,
  imgUrl,
  imgDescription,
  extra_description,
  children,
  id,
}) {
  return (
    <section className="ImageInfo" id={id}>
      <div className="ImageInfo__content">
        <div className={`ImageInfo__info ${invert && "invert"}`}>
          <p className="ImageInfo__meta meta-description">{meta}</p>
          <h2 className="ImageInfo__header">{header}</h2>
          <p className="ImageInfo__description">{description}</p>
          {extra_description && (
            <p className="ImageInfo__description">{extra_description}</p>
          )}
          {children}
        </div>
        <img className="ImageInfo__img" src={imgUrl} alt={imgDescription} />
      </div>
    </section>
  );
}

export { ImageInfo };

import React from "react";
import "./experience.scss";

function Experience() {
  return (
    <section className="Experience">
      <p className="Experience__meta meta-description">Experiencia</p>
      <div className="Experience__top">
        <h3 className="Experience__header">
          Somos profesionales y tenemos la experiencia
        </h3>
        <p className="Experience__description">
          Contámos con un equipo que conoce a fondo las diferentes leyes y
          normas que se aplican al transporte nacional. Solventaremos cualquier
          problema que pueda ocurrir en el trayecto de la mercancía desde el
          punto de origen hasta el punto destino.
        </p>
      </div>
      <div className="Experience__bottom">
        <article className="Experience__stats">
          <h4 className="Experience__stats-title">1,500+</h4>
          <p className="Experience__stats-description">
            Más de 1500 viajes alrededor de la república
          </p>
        </article>
        <article className="Experience__stats">
          <h4 className="Experience__stats-title">17,000+</h4>
          <p className="Experience__stats-description">
            Alrededor de 17,000+ productos transportados
          </p>
        </article>
        <article className="Experience__stats">
          <h4 className="Experience__stats-title">210</h4>
          <p className="Experience__stats-description">
            Clientes satisfechos de más de 4 industrias
          </p>
        </article>
      </div>
    </section>
  );
}

export { Experience };

import React from "react";
import { InfoBlock } from "./InfoBlock";
import "./our-team.scss";

function OurTeam({ title, imgUrl, imgAlt, info }) {
  return (
    <section className="OurTeam">
      <div className="OurTeam__header">
        <p className="OurTeam__meta meta-description">Pardela</p>
        <h3 className="OurTeam__title">{title}</h3>
      </div>
      <div className="OurTeam__content">
        <img src={imgUrl} alt={imgAlt} className="OurTeam__img" />
        <div className="OurTeam__info">
          {info.map((infoBlock, index) => (
            <InfoBlock
              key={index}
              iconUrl={infoBlock.iconUrl}
              iconDescription={infoBlock.iconDesc}
              title={infoBlock.title}
              details={infoBlock.details}
              type="secondary"
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export { OurTeam };

import React from "react";
import { usePardela } from "../../contexts/PardelaContext";
import { Banner } from "../../components/Banner/Banner";
import { MainButton } from "../../components/MainButton/MainButton";
import { ImageInfo } from "../../components/ImageInfo/ImageInfo";
import { ContactHero } from "../../components/ContactHero/ContactHero";
import { Experience } from "../../components/Experience/Experience";
import "./services.scss";

function Services() {
  const { views } = usePardela();

  const bannerDescription = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
  Nam nec lectus sit amet justo laoreet vehicula vitae a ligula. Nam nec porta purus. 
  Quisque tellus magna, dignissim quis tristique ut, volutpat eget lectus.`;

  return (
    <div className="Services">
      <Banner title="Nuestros Servicios" description={bannerDescription}>
        <MainButton
          title="Cotizar"
          type="primary"
          size="sm"
          link={views.contacto}
        />
      </Banner>
      <ImageInfo
        id="esquema-flete"
        invert={false}
        meta="Servicios"
        header="Esquema por flete"
        description={`En ocasiones emergentes se necesitan aliados confiables y seguros, 
        contamos con el esquema SPOT, en el cual realizamos el mejor servicio para nuestros 
        clientes de punto A a punto B. Garantizando la seguridad de nuestros servicios y personal 
        capacitado para el transporte de su mercancía en todo momento.`}
        imgUrl="https://i.ibb.co/x5n81rc/prdl-services-1.jpg"
        imgDescription="Truck on highway"
      />
      <ImageInfo
        id="esquema-dedicado"
        invert={true}
        meta="Servicios"
        header="Esquema dedicado"
        description={`Nuestros clientes al contratar este servicio tendrán la seguridad de 
        tener su flotilla exclusiva, que no podrá ser utilizada por otra empresa, 
        servicio o carga. De esta forma podrán tener mayor libertad para poder realizar 
        sus entregas e incluso poder aumentar su producción sin temor a no tener forma de entregarla. 
        De esta manera solamente se enfocarán en el crecimiento propio, dejando en nuestras manos 
        su logística y transporte. Realizamos entregas a nivel nacional, con la mayor seguridad y 
        profesionalidad para nuestros clientes, con la certeza de que las mercancías llegarán a su 
        destino con la mayor seguridad, agilidad, rapidez y en óptimas condiciones.`}
        imgUrl="https://i.ibb.co/2j37ygG/prdl-services-2.png"
        imgDescription="White truck"
      />
      <ImageInfo
        id="admin-flotillas"
        invert={false}
        meta="Servicios"
        header="Administración de flotas"
        description={`Contamos con el servicio de administración de flotas; a nuestros permisionarios. 
        En los cuales nosotros administramos y operamos su flota al 100%, desde la entrada de las 
        unidades a nuestra operación como la asignación de carga en los diferentes puntos de la 
        república mexicana, apoyándonos de nuestra red de clientes en los diferentes esquemas que trabajamos. 
        Así como los servicios preventivos de las unidades, gps, reclutamiento estricto para encontrar 
        al operador adecuado para cada unidad, así como reportes específicos de cada unidad, 
        evaluando rendimientos gastos operativos entre otros.`}
        imgUrl="https://i.ibb.co/T04x5JD/prdl-services-3.png"
        imgDescription="Two blue trucks"
      />
      <section className="Services__clients">
        <h4 className="Services__clients-title">
          Algunos de nuestros clientes
        </h4>
        {/* <div className="Services__clients-logos">
          <img
            src="https://i.ibb.co/K22rSw5/prdl-clients-cargo.png"
            alt="Cargo Group"
            className="Services__clients-logo"
          />
          <img
            src="https://i.ibb.co/xgD6jTd/prdl-clients-oss.png"
            alt="Oss Group"
            className="Services__clients-logo"
          />
        </div> */}
      </section>
      <Experience />
      <ContactHero />
    </div>
  );
}

export { Services };

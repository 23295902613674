import React from "react";
import "./banner.scss";

function Banner(props) {
  return (
    <div className="Banner">
      <div className="Banner__figure">
        {/* <img src="./img/prdl_figure_logo.png" alt="" /> */}
      </div>
      <div className="Banner__wrapper">
        <h1 className="Banner__title">{props.title}</h1>
        {props.description && (
          <p className="Banner__description">{props.description}</p>
        )}
        <div className="Banner__btn-container">{props.children}</div>
      </div>
    </div>
  );
}

export { Banner };

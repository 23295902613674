import { useState } from "react";
import { Link } from "react-router-dom";
import { usePardela } from "../../contexts/PardelaContext";
import Carousel from "react-bootstrap/Carousel";
import "./textimg-carousel.scss";

function TextImgCarousel({ meta, title, carouselItems }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const { views, setCurrentView } = usePardela();

  return (
    <section className="TextImgCarousel">
      <div className="section-header">
        <p className="meta-description">{meta}</p>
        <h3 className="section-header__title">{title}</h3>
      </div>
      <Carousel
        className="TextImgCarousel"
        activeIndex={index}
        onSelect={handleSelect}
      >
        {carouselItems.map((item) => (
          <Carousel.Item key={item.id}>
            <Carousel.Caption>
              <article className="TextImgCarousel__item">
                <div className="TextImgCarousel__item-content">
                  <div className="TextImgCarousel__item-number-container">
                    <span className="TextImgCarousel__item-number">
                      {item.id}
                    </span>
                    <span className="TextImgCarousel__item-line"></span>
                  </div>
                  <h4 className="TextImgCarousel__item-title">{item.title}</h4>
                  <p className="TextImgCarousel__item-details">
                    {item.details}
                  </p>
                  <Link
                    to={views.servicios}
                    onClick={() => setCurrentView(views.servicios)}
                    className="TextImgCarousel__item-link"
                  >
                    Conocer más &rarr;
                  </Link>
                </div>
                <div className="TextImgCarousel__item-img">
                  <img src={item.imgUrl} alt={item.imgAlt} />
                </div>
              </article>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
}

export { TextImgCarousel };
